<template>
    <div class="row paymentDone text-center" style="padding-top: 150px;">
        <div class="col-12">
            <h4>{{$t('paymentSuccess')}}</h4>
            <img src="@/assets/img/cool-bag.svg">
        </div>
        <div class="col-12">
            <router-link tag="button" class="btn btn-active" :to="this.base + '/ticket'">{{$t('showTicket')}}</router-link>
        </div>
    </div>

</template>

<script>
    import {isNotNull} from "@/assets/js/Utils"
    var Basket = require('@/assets/js/Basket')
    var Customer = require("@/assets/js/Customer")
    var Utils = require("@/assets/js/Utils")
    export default {
        name: 'PaymentDone',
        data(){
            return{
                encodedTicketId : '',
                customerBasket: null,
                fromRoute : null,
                base: localStorage.getItem("DKC_base")
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.fromRoute = from;
            })
        },
        created(){
            var self = this;
            this.customerBasket = this.$parent.basket;
            Basket.validBasket(function(res) {
                localStorage.setItem("DKC_basket", JSON.stringify(res));
                self.$parent.basketChange();

                var loyaltyUse = localStorage.getItem("DKC_loyaltyToUse");
                var ticketAmount = localStorage.getItem("DKC_ticketAmount");
                if(isNotNull(loyaltyUse) && isNotNull(ticketAmount) && isNotNull(self.$parent.customer) && isNotNull(self.$parent.customer.loyalty)){
                    var newNbPoints = self.$parent.customer.loyalty.loyaltyPoint - loyaltyUse;
                    var winPoints = (ticketAmount * Utils.getConfig().loyaltyPourcent) / 100
                    newNbPoints += winPoints;
                    newNbPoints = newNbPoints.toFixed(2);
                    if(newNbPoints > 0){
                        Customer.updateCustomerLoyaltyPoints(newNbPoints);
                    }else{
                        Customer.updateCustomerLoyaltyPoints(0);
                    }
                    localStorage.removeItem("DKC_loyaltyToUse");
                }
            });

        },
        methods:{
            handleBack (fallback) {
                if (!this.fromRoute.name) {
                    this.$router.push(localStorage.getItem("DKC_base") + fallback);
                } else {
                    this.$router.back();
                }
            }
        }
    }
</script>